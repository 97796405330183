.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: white;
  color: black;
}

.App-header {
  padding: 20px;
  border-bottom: 1px solid #000;
}

.App-header h1 {
  font-size: 2.5em;
  margin: 0;
}

.App-header p {
  font-size: 1.2em;
  margin: 5px 0 20px 0;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

nav a:hover {
  text-decoration: underline;
}

section {
  padding: 20px;
  border-bottom: 1px solid #000;
}

section:last-of-type {
  border-bottom: none;
}

h2 {
  font-size: 2em;
  margin-top: 0;
}

p {
  font-size: 1em;
}
